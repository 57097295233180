import request, {
  downloadFile,
  uploadFile,
  applicationJson,
} from "@/utils/axios";

export function getBaseInfo(params) {
  return request.get("/agency/campaign/info", params);
}

export function getKolList(params) {
  return request.get("/agency/campaign/kol", params);
}

export function getFilter(params) {
  return request.get("/campaign/option", params);
}

export function downloadDoc(params, fileName) {
  return downloadFile("/campaign/file", params, fileName);
}

export function downloadKolList(params) {
  return downloadFile("/agency/campaign/kol/file", params, "活动KOL清单.xlsx");
}

export function importKolList(file) {
  return uploadFile("/agency/campaign/quote/batch", file);
}

export function submitPrice(params) {
  return applicationJson.post("/agency/campaign/quote", params);
}
