<template>
  <div style="background-color: #fff; padding: 10px 5px">
    <div class="detail_contain">
      <main-title title="活动基本信息" style="margin-bottom: 30px" />
      <div class="base_info_item_contain" v-loading="infoLoading">
        <p class="base_info_item">
          <span class="base_info_item_label">活动品牌</span>
          <span>{{ basicInfo.brand }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动说明</span>
          <span>{{ basicInfo.campaignDesc }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">相关文档</span>
          <svg-icon
            class-name="download"
            @click="downloadDoc(basicInfo.docDir)"
            style="
              fill: skyblue;
              font-size: 24px;
              cursor: pointer;
              vertical-align: middle;
            "
            v-if="basicInfo.docDir"
          ></svg-icon>
          <span v-else>无</span>
        </p>
        <p class="base_info_item" style="width: 100%">
          <span class="base_info_item_label">活动名称</span>
          <span>{{ basicInfo.campaignName }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动时间</span>
          <span>{{
            basicInfo.duration ? basicInfo.duration.split(",").join(" 至 ") : ""
          }}</span>
        </p>
        <p class="base_info_item">
          <span class="base_info_item_label">活动预算</span>
          <span>￥{{ sliceDecimal(basicInfo.cost / 100) }}</span>
        </p>
      </div>
    </div>
    <div class="detail_contain" style="padding-bottom: 20px">
      <main-title title="报价信息" style="margin-bottom: 20px" />
      <div style="display: flex" v-loading="tableLoading">
        <el-menu
          style="border-right: none; width: 174px"
          :default-active="platform"
          @select="handlePlatform"
        >
          <el-menu-item
            v-for="item in formatSites"
            :key="item.value"
            :index="item.value"
            :style="
              item.value === platform
                ? { borderLeft: '4px solid #51A6F0', paddingLeft: '16px' }
                : { borderLeftColor: '#51A6F0' }
            "
          >
            <div style="padding: 8px 0">
              <p
                style="
                  height: 20px;
                  line-height: 20px;
                  display: flex;
                  align-items: center;
                "
              >
                <svg-icon
                  :class-name="item.value"
                  style="font-size: 18px; margin-right: 8px"
                  :style="{
                    fill: item.value === platform ? '#51A6F0' : '#555',
                  }"
                />
                {{ item.label }}
              </p>
              <p style="height: 20px; line-height: 20px; text-align: center">
                (<span>{{ item.num }}</span>
                <span v-if="item.num">
                  | ￥{{ item.price ? sliceDecimal(item.price) : 0 }} </span
                >)
              </p>
            </div>
          </el-menu-item>
        </el-menu>
        <div class="budget_info_table_contain" ref="tableContain">
          <div class="budget_info_table_filter">
            <el-input
              placeholder="请输入KOL名称"
              prefix-icon="el-icon-search"
              style="width: 200px"
              size="mini"
              v-model="search"
              @change="handleSearch"
              clearable
            >
            </el-input>
            <div style="display: flex">
              <el-button type="primary" size="mini" @click="downloadList">
                <svg-icon
                  class-name="download"
                  style="fill: #fff; margin: 0 3px 1px 0"
                ></svg-icon>
                下载清单
              </el-button>
              <el-tooltip
                placement="top"
                content="请在“下载清单”中下载的excel文件填写报价信息后导入"
              >
                <el-upload
                  :multiple="false"
                  :show-file-list="false"
                  :headers="{ token }"
                  :action="action"
                  :data="{ campaignId, agencyId }"
                  :before-upload="beforeUpload"
                  ref="upload"
                  :on-success="success"
                  :on-error="error"
                  :disabled="campaignStatus !== '2_1'"
                >
                  <el-button
                    style="margin: 0 10px"
                    size="mini"
                    type="primary"
                    :disabled="campaignStatus !== '2_1'"
                  >
                    <svg-icon
                      class-name="upload"
                      style="fill: #fff; margin: 0 3px 1px 0"
                    ></svg-icon>
                    批量导入
                  </el-button>
                </el-upload>
              </el-tooltip>
              <el-button
                v-if="!handleColStatus"
                type="primary"
                size="mini"
                @click="handleColStatus = true"
                :disabled="campaignStatus !== '2_1'"
                icon="el-icon-edit"
              >
                修改
              </el-button>
              <el-button
                v-else
                type="primary"
                size="mini"
                @click="submitQuote"
                :disabled="waitSubmit"
              >
                <i class="el-icon-loading" v-show="waitSubmit"></i>
                保存
              </el-button>
            </div>
          </div>
          <el-table
            :data="campaignTableInfo"
            :header-cell-style="headerStyle"
            :cell-style="cellStyle"
            stripe
            :style="{ width: tableWidth }"
          >
            <el-table-column
              label="KOL名称"
              prop="kolName"
              width="180"
              :show-overflow-tooltip="true"
              fixed
            >
              <template #default="scope">
                <div style="display: flex; align-items: center">
                  <img
                    style="
                      border-radius: 50%;
                      width: 30px;
                      height: 30px;
                      margin-right: 5px;
                    "
                    :src="scope.row.logoUrl"
                    alt=""
                  />
                  <span>{{ scope.row.kolName }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              label="平台"
              prop="platform"
              align="center"
              fixed
              width="80"
            >
              <template #default="scope">
                {{ platformToZn(scope.row.platform) }}
              </template>
            </el-table-column>
            <el-table-column
              v-for="item in colData"
              :key="item.prop"
              :label="item.label"
              :prop="item.prop"
              :align="item.align"
              :width="item.width"
              :fixed="item.fixed"
            />
            <el-table-column
              label="粉丝数"
              prop="kolFans"
              align="center"
              width="120"
            >
              <template #default="scope">
                {{
                  scope.row.kolFans ? sliceDecimal(scope.row.kolFans) : "N/A"
                }}
              </template>
            </el-table-column>
            <el-table-column
              label="合作类型"
              prop="coopType"
              align="center"
              width="160"
            >
              <template #default="scope">
                <el-select
                  v-if="handleColStatus"
                  v-model="scope.row.coopType"
                  size="mini"
                  clearable
                >
                  <el-option
                    v-for="item in coopOptions(scope.row.platform)"
                    :key="item.id"
                    :label="item.coopName"
                    :value="item.coopName"
                  ></el-option>
                </el-select>
                <p v-else>{{ scope.row.coopType }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="权益类型"
              prop="rightsType"
              align="center"
              width="220"
            >
              <template #default="scope">
                <el-select
                  v-if="handleColStatus"
                  v-model="scope.row.rightsType"
                  size="mini"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in rightsOptions(scope.row.platform)"
                    :key="item.id"
                    :label="item.rightsName"
                    :value="item.rightsName"
                  ></el-option>
                </el-select>
                <p v-else>
                  {{ scope.row.rightsType ? scope.row.rightsType.join() : "" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="内容类型"
              prop="contentType"
              align="center"
              width="200"
            >
              <template #default="scope">
                <el-select
                  v-if="handleColStatus"
                  v-model="scope.row.contentType"
                  size="mini"
                  clearable
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="item in contentOptions(scope.row.platform)"
                    :key="item.id"
                    :label="item.rightsName"
                    :value="item.rightsName"
                  ></el-option>
                </el-select>
                <p v-else>
                  {{
                    scope.row.contentType ? scope.row.contentType.join() : ""
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="其他权益"
              prop="otherRights"
              align="center"
              width="200"
            >
              <template #default="scope">
                <el-input
                  v-if="handleColStatus"
                  v-model.trim="scope.row.otherRights"
                  placeholder="请输入其他权益"
                  size="mini"
                ></el-input>
                <p v-else>{{ scope.row.otherRights }}</p>
              </template>
            </el-table-column>
            <el-table-column
              label="排期"
              prop="schedule"
              align="center"
              width="240"
            >
              <template #default="scope">
                <el-date-picker
                  v-if="handleColStatus"
                  v-model="scope.row.schedule"
                  type="daterange"
                  size="mini"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="YYYY-MM-DD"
                  style="width: 210px"
                >
                </el-date-picker>
                <p v-else>
                  {{
                    scope.row.schedule ? scope.row.schedule.join(" 至 ") : ""
                  }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="报价"
              prop="price"
              align="center"
              width="130"
            >
              <template #default="scope">
                <el-input
                  v-if="handleColStatus"
                  v-model="scope.row.price"
                  @blur="judgePrice"
                  size="mini"
                  style="width: 120px"
                ></el-input>
                <p v-else>
                  {{ scope.row.price ? sliceDecimal(scope.row.price) : "" }}
                </p>
              </template>
            </el-table-column>
            <el-table-column
              label="备注"
              prop="comments"
              align="center"
              width="120"
            >
              <template #default="scope">
                <el-input
                  v-if="handleColStatus"
                  v-model.trim="scope.row.comments"
                  placeholder="请输入备注"
                  size="mini"
                ></el-input>
                <p v-else>{{ scope.row.comments }}</p>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mainTitle from "@/components/common/mainTitle";
import {
  getBaseInfo,
  getKolList,
  getFilter,
  downloadDoc,
  downloadKolList,
  submitPrice,
} from "@/api/quoteDetail";
import { mapGetters } from "vuex";
import { nextTick } from "vue";

export default {
  name: "quoteDetail",
  data() {
    return {
      basicInfo: {
        brand: "",
        campaignDesc: "",
        campaignName: "",
        duration: "",
        cost: null,
        docDir: "",
      },
      // 表格样式
      headerStyle: {
        backgroundColor: "#F9FCFE",
        borderTopWidth: "1px",
        borderTopColor: "#eee",
        borderTopStyle: "solid",
      },
      cellStyle: {
        fontSize: "12px",
      },
      colData: [
        {
          width: 200,
          align: "center",
          prop: "pKolid",
          label: "KOL ID",
          fixed: true,
        },
        {
          width: 80,
          align: "center",
          prop: "kolCategory",
          label: "类型",
          fixed: false,
        },
      ],
      campaignTableInfo: [],
      tableList: [],
      coopList: [],
      rightsList: [],
      contentList: [],
      search: "",
      handleColStatus: false,
      platform: "all",
      action: process.env.VUE_APP_API_HOST + "/agency/campaign/quote/batch",
      waitSubmit: false,
      infoLoading: false,
      tableLoading: false,
      tableWidth: "900px",
    };
  },
  props: {
    campaignId: {
      type: Number,
      default: 1,
    },
    agencyId: {
      type: Number,
      default: 1,
    },
    campaignStatus: {
      type: String,
      default: "2_1",
    },
    round: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    ...mapGetters(["token", "sites"]),
    formatSites() {
      let totalData = {
        label: "全平台",
        value: "all",
        num: 0,
        price: 0,
      };
      const list = this.sites.map(({ value, label }) => {
        const tempArr = this.tableList.filter((item) => {
          return item.platform === value;
        });
        const num = tempArr.length;
        let price = 0;
        tempArr.forEach((item) => {
          item.price ? (price += item.price) : null;
        });
        totalData.num += num;
        totalData.price += price;
        return {
          label,
          value,
          num,
          price,
        };
      });
      list.unshift(totalData);
      return list;
    },
  },
  mounted() {
    this.getOptions();
    this.initBaseInfo();
    this.initTableList();
    nextTick(() => {
      this.tableWidth = this.$refs.tableContain.clientWidth - 10 + "px";
    });
  },
  methods: {
    async getOptions() {
      const {
        data: { coopList, rightsList, contentList },
      } = await getFilter();
      this.coopList = coopList;
      this.rightsList = rightsList;
      this.contentList = contentList;
    },
    async initBaseInfo() {
      this.infoLoading = true;
      const params = { campaignId: this.campaignId };
      const { data } = await getBaseInfo(params);
      this.basicInfo = data;
      this.infoLoading = false;
    },
    async initTableList() {
      this.tableLoading = true;
      this.platform = "all";
      this.search = "";
      this.handleColStatus = false;
      const params = { agencyId: this.agencyId, campaignId: this.campaignId };
      const {
        data: { kolList },
      } = await getKolList(params);
      this.formatTableList(kolList);
    },
    formatTableList(list) {
      this.tableList = list.map((item) => {
        item.schedule = item.schedule ? item.schedule.split(",") : null;
        item.price = item.price ? item.price / 100 : undefined;
        item.rightsType = item.rightsType ? item.rightsType.split(",") : null;
        item.contentType = item.contentType
          ? item.contentType.split(",")
          : null;
        return item;
      });
      this.campaignTableInfo = JSON.parse(JSON.stringify(list));
      this.tableLoading = false;
    },
    downloadDoc(filePath) {
      const i = filePath.lastIndexOf("/");
      const fileName = filePath.slice(i + 1);
      downloadDoc({ fileName }, fileName);
    },
    downloadList() {
      const params = { agencyId: this.agencyId, campaignId: this.campaignId };
      downloadKolList(params);
    },
    beforeUpload(file) {
      // 对上传的文件进行限制
      const testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "xlsx" || testmsg === "xls";
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!extension) {
        this.$message({
          message: "上传文件只能是xlsx或者xls格式!",
          type: "warning",
        });
      }
      if (!isLt10M) {
        this.$message({
          message: "上传文件大小不能超过 10MB!",
          type: "warning",
        });
      }
      return extension && isLt10M;
    },
    success(res, file, fileList) {
      // 上传成功
      if (!res.code) {
        this.$message({
          message: "批量导入成功！",
          type: "success",
        });
        this.initTableList();
      } else {
        this.$refs.upload.onError(res.code, file, fileList);
      }
    },
    error(err) {
      let message;
      switch (err) {
        case 204:
          message = "文件内容格式错误";
          break;
        default:
          message = "上传文件失败!";
      }
      // 上传失败
      this.$message({
        message: message,
        type: "error",
      });
    },
    async submitQuote() {
      if (!this.campaignTableInfo.length) {
        return;
      }
      this.waitSubmit = true;
      let params = { agencyId: this.agencyId, campaignId: this.campaignId };
      const kolList = this.campaignTableInfo.map(
        ({
          ckId,
          cqId,
          schedule,
          price,
          comments,
          coopType,
          rightsType,
          contentType,
          otherRights,
        }) => {
          price = this.formatStringNum(price);
          return {
            ckId,
            cqId,
            schedule: schedule ? schedule.join() : null,
            price: price && price > 0 ? price * 100 : null,
            comments,
            coopType,
            rightsType: rightsType ? rightsType.join() : null,
            contentType: contentType ? contentType.join() : null,
            otherRights,
          };
        }
      );
      params.kolList = kolList;
      const { code } = await submitPrice(params);
      if (!code) {
        this.$message({
          message: "数据保存成功！",
          type: "success",
        });
        this.handleColStatus = false;
        await this.initTableList();
      }
      this.waitSubmit = false;
    },
    handleSearch(search) {
      let list = this.tableList.filter((item) => {
        return item.kolName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
      if (this.platform !== "all") {
        list = list.filter((item) => {
          return item.platform === this.platform;
        });
      }
      this.campaignTableInfo = JSON.parse(JSON.stringify(list));
      this.handleColStatus = false;
    },
    handlePlatform(platform) {
      this.platform = platform;
      this.handleColStatus = false;
      this.search = "";
      if (platform === "all") {
        this.campaignTableInfo = JSON.parse(JSON.stringify(this.tableList));
      } else {
        const list = this.tableList.filter((item) => {
          return item.platform === platform;
        });
        this.campaignTableInfo = JSON.parse(JSON.stringify(list));
      }
    },
    coopOptions(platform) {
      const list = this.coopList.filter((item) => {
        return item.platform === platform;
      });
      return list;
    },
    rightsOptions(platform) {
      const list = this.rightsList.filter((item) => {
        return item.platform === platform;
      });
      return list;
    },
    contentOptions(platform) {
      const list = this.contentList.filter((item) => {
        return item.platform === platform;
      });
      return list;
    },
    judgePrice(e) {
      e.target.value = this.formatStringNum(e.target.value);
    },
    formatStringNum(num) {
      let res = null;
      if (num) {
        const numStr = num.toString();
        const index = numStr.indexOf(".");
        res = index === -1 ? numStr : numStr.slice(0, index);
        res = res.replace(/\D/g, "") * 1;
        if (res < 1) {
          res = null;
        }
      }
      return res;
    },
  },
  components: {
    mainTitle,
  },
};
</script>

<style scoped lang="scss">
.detail_contain {
  .base_info_item_contain {
    padding-left: 20px;
    display: flex;
    flex-wrap: wrap;

    .base_info_item {
      height: 50px;
      width: 30%;
      margin-right: 3%;

      .base_info_item_label {
        margin-right: 30px;
        font-size: 16px;
        font-weight: 700;
        color: #606266;
      }
    }
  }

  .budget_info_table_contain {
    flex: 1;
    padding: 6px 0 0 10px;

    .budget_info_table_filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
  }
}

.i_point {
  cursor: pointer;
}
</style>
